<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 人工生成 </template>
    </HeaderBox>

    <div class="list-box">
      <div class="certificate-list">
        <template v-if="certificateList.length">
          <div
            class="certificate-item"
            v-for="item in certificateList"
            :key="item.tempId"
            @click="onTab(item)"
          >
            <span :class="{ 'hover-span': tempId == item.tempId }">{{
              item.certifName
              }}</span>
          </div>
        </template>
        <a-empty class="empty" v-else />
      </div>
      <div class="table-box">
        <h4 class="course-name">关联课程：{{ courseName }}</h4>
        <div class="flex-div">
          <a-input class="input" v-model="queryParams.name" placeholder="请输入姓名" allowClear />
          <a-input class="input" v-model="queryParams.mobile" placeholder="请输入手机号" allowClear />
          <a-range-picker class="input" @change="onSelectTime" :placeholder="['开始日期', '结束日期']" format="YYYY-MM-DD" />
          <a-button type="primary" class="btn" :loading="loading" v-hasPermi="['certif:data:list']"
            @click="search()">搜索</a-button>
        </div>
        <div class="flex-div">
          <a-button class="btn" @click="addNewData()" v-hasPermi="['certif:data:add']">新增人员</a-button>
          <a-button class="btn" @click="downCertifData()">下载证书数据模板</a-button>
          <a-button class="btn" @click="downExcleData()">下载用户模板</a-button>
          <a-upload name="videoFileId" :beforeUpload="beforeUpload" :showUploadList="false"
            :customRequest="customRequest" :loading="uploadLoading">
            <a-button v-hasPermi="['certif:data:add']">导入证书信息</a-button>
          </a-upload>
          <a-button class="btn" :loading="loading" v-hasPermi="['certif:data:generation']"
            @click="onShowTime(1)">勾选生成证书</a-button>
          <a-button class="btn" :loading="loading" v-hasPermi="['certif:data:generation']"
            @click="onShowTime(2)">全部证书生成</a-button>
            <a-button class="btn" :loading="loading" v-show="tempId == 10018 || tempId == 10019" @click="onCertSync()">证书同步</a-button>
        </div>
        <a-table class="table-template" :rowKey="(item) => item.dataId" :columns="columns" :loading="loading"
          :data-source="tableData" @change="onPage" :row-selection="{
            selectedRowKeys: selectedSignUpRowKeys,
            onChange: select,
            columnTitle: '选择',
            getCheckboxProps: (record) => ({
              props: {
                disabled: record.status == 1,
              },
            }),
          }" :pagination="{
            total: total,
            current: queryParams.pageNum,
            defaultPageSize: queryParams.pageSize,
            showSizeChanger: true,
            showTotal: function (total, range) {
              return `共${total}条`;
            },
          }">
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
            </div>
          </template>
          <template slot="status" slot-scope="item">
            <span :class="item == 1 ? 'state_color_green' : 'state_color_red'">{{ item == 1 ? "是" : "否" }}</span>
          </template>
          <template slot="confirm" slot-scope="item">
            <span :class="item == 1 ? 'state_color_green' : 'state_color_red'">{{ item == 1 ? "是" : "否" }}</span>
          </template>

          <template slot="operation" slot-scope="item, row">
            <div class="btn_router_link">
              <a @click="goPage(row.dataId, row.status)" v-hasPermi="['certif:data:update']">修改</a>
              <span v-hasPermi="['certif:data:del']">|</span>
              <a @click="deleteData(item.dataId)" v-hasPermi="['certif:data:del']">删除</a>
            </div>
          </template>

          <!-- <template slot="operation">
            <div class="btn_router_link">
              <a>生成证书信息</a>
            </div>
          </template> -->
        </a-table>
      </div>
    </div>
    <!-- 更新发证时间 -->
    <a-modal title="更新发证时间" :visible="timeShow" width="360px" @ok="handleOk" @cancel="handleCancel">
      <div class="modal_box">
        <a-date-picker class="input-time" v-model="getcertifTime" placeholder="请选择更新时间" @change="onDataChange" />
      </div>
    </a-modal>

    <!-- 数据弹窗 -->
    <LeadingIn :columns="columnsss" :successList="successList" :failureList="failureList" :visible="importVisible"
      :handleCancel="modelhandleCancel" />
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
const columnsss = [
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "性别",
    align: "center",
    dataIndex: "sex",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
  },
  {
    title: "职称/职务",
    align: "center",
    dataIndex: "occupationName",
  },
  {
    title: "学历",
    align: "center",
    dataIndex: "degree",
  },
  {
    title: "出生日期",
    align: "center",
    dataIndex: "birthday",
  },
];
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "学历",
    align: "center",
    dataIndex: "degree",
  },
  {
    title: "职称/职务",
    align: "center",
    dataIndex: "occupationName",
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "发证时间",
    align: "center",
    dataIndex: "getcertifTime",
  },
  // {
  //   title: "是否已生成证书",
  //   align: "center",
  //   dataIndex: "status",
  //   scopedSlots: { customRender: "status" },
  // },
  {
    title: "是否已确认信息",
    align: "center",
    dataIndex: "confirm",
    scopedSlots: { customRender: "confirm" },
  },
  {
    title: "操作",
    align: 'center',
    width: "160px",
    scopedSlots: { customRender: "operation" },
  }
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columnsss,
      loading: false,
      columns, // 表头
      tableData: [], // 列表数据
      certificateList: [],
      queryParams: {
        name: "", // 姓名
        mobile: "", // 手机号
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        timeIds: "", // 场次id
        pageNum: 1,
        pageSize: 10,
      },
      total: 0, // 总数量
      selectedSignUpRowKeys: [],
      tempId: 0, // 模板id
      courseName: "", // 课程名称
      timeShow: false,
      timeIndex: 0,
      getcertifTime: null,

      uploadLoading: false,

      // 弹框
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.queryParams.pageNum = e.current;
      this.queryParams.pageSize = e.pageSize;
      this.getDataList();
    },

    onTab(e) {
      this.tempId = e.tempId;
      this.courseName = e.courseName;
      this.queryParams.tempId = e.tempId;
      this.queryParams.pageNum = 1;
      this.getDataList();
    },

    //更新发证时间
    handleOk(e) {
      if (!this.getcertifTime) {
        this.$message.warning("请选择更新发证时间");
        return;
      }
      let params = {};
      params.getcertifTime = this.getcertifTime;
      if (this.timeIndex == 1) {
        params.dataIds = this.selectedSignUpRowKeys;
      } else {
        params.tempId = this.tempId;
      }
      this.$ajax({
        method: "PUT",
        url: "/hxclass-management/certifData/updateReleaseTime",
        params: params,
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            if (this.timeIndex == 1) {
              this.onSelectCertificate();
            } else {
              this.onAllCreateCertificate();
            }
            this.getcertifTime = null;
            this.timeShow = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    handleCancel(e) {
      this.timeShow = false;
    },

    onDataChange(e, v) {
      this.getcertifTime = v;
    },

    // 查询证书配置列表
    getCertificateList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/certiftemp/getManualGenerationConfig",
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.certificateList = res.data;
            if (res.data.length) {
              this.tempId = res.data[0].tempId;
              this.courseName = res.data[0].courseName;
              this.queryParams.tempId = res.data[0].tempId;
              this.getDataList();
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },

    // 查询手动配置列表
    getDataList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/certifData/getCertifDataList",
        params: this.queryParams,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.tableData = res.data.records;
            this.total = res.data.total;
          } else {
            this.tableData = [];
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },

    // 试题附件上传前钩子
    beforeUpload(file) {
      var reg = /\.(xlsx|xls)(\?.*)?$/;
      if (!reg.test(file.name)) {
        this.$message.warning("仅支持：xlsx、xls格式文件");
        return false;
      }

      if (this.uploadLoading) {
        this.$message.warning("附件正在上传中，请稍后重试");
        return false;
      }
    },

    // 附件上传 - 单文件
    customRequest(fileData) {
      this.uploadLoading = true;
      this.$ajax({
        method: "POST",
        url: "/hxclass-management/certifData/dataExcel",
        headers: [{ type: "file" }],
        params: {
          file: fileData.file,
          name: "file",
        },
      })
        .then((res) => {
          this.uploadLoading = false;
          this.successList = res.data.ok;
          this.failureList = res.data.err;
          this.importVisible = true;
        })
        .catch((error) => {
          this.uploadLoading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
      this.progress = progress;
      this.speed = speed;
    },

    modelhandleCancel() {
      this.importVisible = false;
    },

    // 下载错误excel
    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      window.open(
        this.$config.target +
        "/hxclass-management/certifData/exportErrorData?json=" +
        encodeURIComponent(JSON.stringify(this.failureList))
      );
    },
    // blob文件转excel
    blobDownload(data, name) {
      let m = this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name + ".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },
    // 导入正确数据
    importSuccessList() {
      if (!this.successList.length) {
        return;
      }
      let arr = this.certificateList.filter((item) => {
        return this.tempId == item.tempId;
      });
      console.log(arr);
      if (!arr.length) {
        return;
      }
      this.$ajax({
        url: "/hxclass-management/certifData/dataImport",
        method: "POST",
        params: {
          courseId: arr[0].courseId,
          dataVOList: this.successList,
          tempId: this.tempId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getDataList();
          this.importVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 新增人员
    addNewData() {
      this.$router.push({
        path: "/admin/BasicConfig/CertificateHandTemplateAdd",
        query: { dataId: this.tempId },
      });
    },

    // 下载用户证书模板
    downExcleData() {
      window.open(
        this.$config.target +
        "/hxclass-management/certifData/excelData?status=0&tempId=" +
        encodeURIComponent(this.queryParams.tempId)
      );
    },

    // 下载证书数据模板
    downCertifData() {
      window.open(
        "https://cos.hxclass.cn/prod/template/course/%E5%AF%BC%E5%85%A5%E8%AF%81%E4%B9%A6%E7%94%A8%E6%88%B7%E6%B8%85%E5%8D%95.xls"
      );
    },

    onShowTime(index) {
      if (index == 1 && !this.selectedSignUpRowKeys.length) {
        this.$message.warning("请勾选后进行生成");
        return;
      }
      this.timeShow = true;
      this.timeIndex = index;
    },

    // 勾选生成证书
    onSelectCertificate() {
      this.loading = true;
      this.$ajax({
        method: "POST",
        url: "/hxclass-management/certifData/manualGeneration",
        params: {
          courseId: this.queryParams.courseId,
          tempId: this.tempId,
          dataIdList: this.selectedSignUpRowKeys,
        },
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("后台正在生成中，请稍后到证书物流管理中查看");
          this.selectedSignUpRowKeys = [];
          this.getDataList();
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },

    // 生成全部证书
    onAllCreateCertificate() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/certifData/fullCourtGeneration",
        params: {
          courseId: this.queryParams.courseId,
          tempId: this.tempId,
          timeIds: null,
        },
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("后台正在生成中，请稍后到证书物流管理中查看");
          this.getDataList();
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },

    // 选择时间
    onSelectTime(date, dateString) {
      this.queryParams.startTime = dateString[0]; // 开始日期
      this.queryParams.endTime = dateString[1]; // 结束日期
    },

    search() {
      if (!this.queryParams.tempId) {
        return this.$message.error("暂无可查数据");
      }
      this.queryParams.pageNum = 1;
      this.getDataList();
    },

    // 批量审核存放报名id
    select(selectedRowKeys) {
      this.selectedSignUpRowKeys = selectedRowKeys;
    },

    // 修改操作，进入证书详情
    // 证书生成
    goPage(e, r) {
      this.$router.push({
        path: "/admin/BasicConfig/CertificateHandTemplateDetail",
        query: { dataId: e },
      });
      // const { href } = this.$router.resolve({
      //   path: `/admin/BasicConfig/CertificateHandTemplateDetail`,
      //   query: {
      //     dataId: e
      //   },
      // });
      // window.open(href, '_blank');
    },
    // 删除操作
    deleteData(e) {
      this.loading = true;

      let _this = this;
      this.$confirm({
        title: "确定删除该证书吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/certifData/delById/" + e,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.loading = false;
                _this.getDataList();
              }
            });
        },
        onCancel() { },
      });
    },
    // 证书同步
    onCertSync() {
      this.loading = true;
      this.$ajax({
        method: "post",
        url: "/hxclass-management/procedure/getProcedure",
        params: {
          inString: "1",
          pName: "sb_changeGuangdongCertifTemp",
        },
      })
        .then((res) => {
          this.loading = false;
          this.getDataList();
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getCertificateList();
  },
  // 生命周期-实例挂载后调用
  mounted() { },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.list-box {
  display: flex;
  margin-top: 20px;

  .certificate-list {
    width: 300px;
    min-width: 300px;
    border: 1px solid #eeeeee;

    .certificate-item {
      padding: 14px 30px;
      border-bottom: 1px solid #eeeeee;
      color: #333;
      display: flex;
      align-items: center;
      cursor: pointer;

      >span {
        line-height: 22px;
      }

      &:hover {
        >span {
          color: @theme;
        }
      }

      .hover-span {
        color: @theme;

        &::after {
          content: "";
          display: inline-block;
          margin-left: 5px;
          width: 0px;
          height: 0px;
          border: 6px solid @theme; //白色
          border-top-color: transparent;
          border-bottom-color: transparent;
          border-left-color: @theme;
          border-right-color: transparent;
        }
      }
    }

    .empty {
      margin-top: 100px;
    }
  }

  .table-box {
    margin-left: 20px;
    flex: 1;

    .course-name {
      font-size: 20px;
      font-weight: 600;
    }

    .flex-div {
      margin-top: 20px;
      display: flex;
      justify-content: right;

      .input {
        width: 20%;
        max-width: 200px;
        margin-left: 30px;
      }

      .btn {
        margin: 0 15px;
      }
    }
  }
}

.modal_box {
  display: flex;
  align-items: center;

  .input-time {
    width: 100%;
  }
}
</style>
